import classNames from 'classnames';
import React from 'react';
import Tooltip from '../Tooltip';

export enum TagType {
  Default,
  Primary,
  Danger,
  Success,
  Link,
  WarningOutlined,
  Warning,
}

export enum TagSize {
  Small,
  Medium,
}

interface Props {
  size?: TagSize;
  rounded?: boolean;
  type?: TagType;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
}

export default function Tag({
  children,
  className,
  size = TagSize.Medium,
  type = TagType.Default,
  rounded,
  onClick,
  tooltip,
}: Props): JSX.Element {
  return (
    <Tooltip disabled={!tooltip} content={tooltip}>
      <span
        onClick={onClick}
        className={classNames('inline-flex items-center justify-center leading-none border', className, {
          'text-white bg-primary': type === TagType.Primary,
          'text-gray-600 bg-white border-gray-300': type === TagType.Default,
          'text-white bg-danger-600': type === TagType.Danger,
          'text-warning-600 border-warning-600 bg-white': type === TagType.WarningOutlined,
          'text-white bg-warning-600': type === TagType.Warning,
          'text-white bg-success-700': type === TagType.Success,
          'px-2 py-1 rounded-lg text-sm': size === TagSize.Medium,
          'px-2 py-1 rounded-full text-sm': size === TagSize.Medium && rounded,
          'px-1 py-[3px] rounded-lg text-xs': size === TagSize.Small,
          'rounded-full text-xs ': size === TagSize.Small && rounded,
        })}
      >
        {children}
      </span>
    </Tooltip>
  );
}
