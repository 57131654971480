/**
 * Implement a serviceWorker into our scope
 */
export default class ServiceWorker {
  public readonly registrationOptions: RegistrationOptions = { scope: '/' };
  public scope: string;
  public isAvailable = Promise.resolve(false);

  public constructor() {
    this.isAvailable = this.initServiceWorker().then(res => res !== null);
  }

  public getRegistration(): Promise<ServiceWorkerRegistration | undefined> {
    return navigator.serviceWorker.getRegistration(this.registrationOptions.scope);
  }

  /**
   * This method does a couple of tasks to load the service worker
   * - Init the service worker if its not loaded yet / or return the ref to the current service-worker if exists
   * - Do a version check in case we load the service worker for the first time
   * - Do a cache cleanup in case we load the service worker for the first time
   */
  private async initServiceWorker(): Promise<ServiceWorkerRegistration | null> {
    if (!navigator.serviceWorker) {
      console.error('No service worker available');
      return null;
    }
    try {
      if (navigator.serviceWorker.controller === null) {
        // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/controller
        // https://github.com/mswjs/msw/issues/98#issuecomment-612118211
        console.warn(
          'Service worker controller is null. Most likely caused by a SHIFT + F5 refresh. Doing a hard reload on the service worker.',
        );
        // Uninstall service worker(s)
        await Promise.all((await navigator.serviceWorker.getRegistrations()).map(r => r.unregister()));
      }
      let reg = await this.getRegistration();
      if (reg) {
        // Service worker already installed. Do version check
        if ((await this.serviceWorkerVersionMatch(reg)) === false) {
          // Uninstall service worker(s)
          await Promise.all((await navigator.serviceWorker.getRegistrations()).map(r => r.unregister()));

          // Install fresh new service worker that should have the same version.
          reg = await navigator.serviceWorker.register('/service-worker.js', this.registrationOptions);
        }

        // clear cache if needed
        await this.cleanup(reg);
      } else {
        reg = await navigator.serviceWorker.register('/service-worker.js', this.registrationOptions);
      }

      this.scope = reg.scope;

      // In production, we cannot load environment variables from process.env.
      // Instead, they are loaded from the window object via /config/env-config.js.
      // However, the service worker does not have access to the window object.
      // Therefore, we need to send the environment variables to the service worker.
      if (reg.active) {
        // eslint-disable-next-line
        // @ts-ignore
        reg.active.postMessage({ type: 'SET_ENV', env: window._env_ });
      }

      return reg;
    } catch (error) {
      let message = 'Unknown error';
      if (error instanceof Error) {
        message = error.message;
      } else if (typeof error === 'string') {
        message = String(error);
      }
      console.error(`Failed to install service worker. ${message}`);
      return null;
    }
  }

  /**
   * Checks if the given service worker has the same git hash as we (the app) have.
   * @return True when identical
   */
  private async serviceWorkerVersionMatch(reg: ServiceWorkerRegistration): Promise<boolean> {
    // eslint-disable-next-line
    // @ts-ignore
    const appGitCommitHash = `${GIT_COMMIT_HASH}`;

    const response = await fetch(`${reg.scope}sw_version`, {
      method: 'GET',
    });
    if (response.status === 200) {
      const swGitCommitHash = await response.text();

      if (swGitCommitHash === appGitCommitHash) {
        return true;
      } else {
        const errorMsg = `Version mismatch between service worker (${swGitCommitHash}) and app (${appGitCommitHash}).`;
        console.error(errorMsg);
        return false;
      }
    } else {
      throw Error('Failed to get service worker version');
    }
  }

  /**
   * Calls the cleanup method in an active service worker.
   */
  public async cleanup(reg: ServiceWorkerRegistration): Promise<void> {
    if (!reg.active) {
      return;
    }
    const response = await fetch(`${reg.scope}sw_cleanup`, {
      method: 'GET',
    });
    if (response.status !== 200) {
      throw Error('Failed to cleanup service worker');
    }
  }
}
