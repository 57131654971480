import { useThemeContext } from 'context/ThemeContext';
import { RealmOrigin } from 'types/RealmOrigin';
import { useEffect } from 'react';
import { origin } from 'util/RealmOrigin';

interface ReturnType {
  theme: RealmOrigin;
  isStorroTheme: boolean;
  isAgencyTheme: boolean;
  companyName: string;
}

/**
 * Small custom hook that detect what kind of theme we are running
 */
function useTheme(): ReturnType {
  const { theme, setTheme } = useThemeContext();

  /**
   * Based on the url we can determine if we should set the theme for Agency Drive
   */
  useEffect(() => {
    setTheme(origin());
  }, [setTheme]);

  return {
    theme,
    isStorroTheme: theme === RealmOrigin.Storro,
    isAgencyTheme: theme === RealmOrigin.AgencyDrive,
    companyName: theme === RealmOrigin.Storro ? 'Storro' : 'Agency Drive',
  };
}

export default useTheme;
