export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isEmail(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export const HEX_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
export function isHex(value: string): boolean {
  return HEX_REGEX.test(value);
}

/**
 * Check if the given value is a string, if not throw an error or return the
 * value
 *
 * @param value
 * @param fieldName
 */
export function stringValidator(value: unknown, fieldName?: string): string {
  if (fieldName === undefined) {
    fieldName = `<value:${value}>`;
  }
  if (typeof value !== 'string' || value === '') {
    // TODO: This should throw in production not in tests.
    throw Error(`${fieldName} must be a non-empty string`);
  }

  return String(value);
}
