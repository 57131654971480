import './wdyr'; // Need to be the first import!!
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import sodium from 'libsodium-wrappers';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Storro from './api';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { IS_MOBILE_APP } from './const';
import { BannerProvider } from './context/BannerContext';
import { BreadcrumbProvider } from './context/BreadcrumbContext';
import { ClientPortalProvider } from './context/ClientPortalContext';
import { DialogProvider } from './context/DialogContext';
import { MenuProvider } from './context/MenuContext';
import { PageLoaderProvider } from './context/PageLoaderContext';
import { ProjectProvider } from './context/ProjectContext';
import { RealmProvider } from './context/RealmContext';
import { SidebarLeftProvider } from './context/SidebarLeftContext';
import { SidebarRightProvider } from './context/SidebarRightContext';
import { SkipPlanProvider } from './context/SkipSelectPlanContext';
import { SplitScreenProvider } from './context/SplitScreenContext';
import { StorroApiProvider } from './context/StorroApiContext';
import { UserProvider } from './context/UserContext';
import LoaderWrapper from './LoaderWrapper';
import { getEnvUrls, validEnvVars } from './util/Util/EnvVar';
import { PageActionProvider } from 'context/PageActionContext';
import { DEFAULT_THEME, ThemeProvider } from 'context/ThemeContext';
import { HelmetProvider } from 'react-helmet-async';

// We need lib sodium right from the start. Wait for ready before doing anything else.
await sodium.ready;

/**
 * Init the Api and pass it to the StorroApiProvider
 * This way we ensure that the API is loaded one time and no more than that
 */
const initApi = (): Storro => {
  const { apiUrl, wsUrl } = getEnvUrls();
  return new Storro(apiUrl, wsUrl);
};

const container = document.getElementById('root');
const root = createRoot(container!); //eslint-disable-line

/**
 * Only load the app when all Env Vars are valid
 */
if (validEnvVars()) {
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <ThemeProvider theme={DEFAULT_THEME}>
            <HelmetProvider>
              <SkipPlanProvider skipPlan={false}>
                <RealmProvider initialLoaded={false}>
                  <UserProvider>
                    <StorroApiProvider storroApi={initApi()}>
                      <PageLoaderProvider loading={false}>
                        <BreadcrumbProvider>
                          <PageActionProvider>
                            <MenuProvider>
                              <BannerProvider>
                                <LoaderWrapper>
                                  <SidebarRightProvider>
                                    <SidebarLeftProvider>
                                      <DialogProvider dialogsItems={[]}>
                                        <SplitScreenProvider showSidebar={false}>
                                          <ClientPortalProvider>
                                            <ProjectProvider>
                                              <App />
                                            </ProjectProvider>
                                          </ClientPortalProvider>
                                        </SplitScreenProvider>
                                      </DialogProvider>
                                    </SidebarLeftProvider>
                                  </SidebarRightProvider>
                                </LoaderWrapper>
                              </BannerProvider>
                              <ToastContainer stacked={true} position={IS_MOBILE_APP ? 'bottom-right' : 'top-right'} />
                            </MenuProvider>
                          </PageActionProvider>
                        </BreadcrumbProvider>
                      </PageLoaderProvider>
                    </StorroApiProvider>
                  </UserProvider>
                </RealmProvider>
              </SkipPlanProvider>
            </HelmetProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>,
  );
} else {
  root.render(
    <React.StrictMode>
      <p>Invalid environment variables. See console log for more info.</p>
    </React.StrictMode>,
  );
}
