import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { STANDALONE_PAGES } from '../const';
import { Realm } from '../types';
import useRealm from './realm/UseRealm';
import useUser from './useUser';

/**
 * Custom hook that load the realms and ensure we are redirecting from '/' to '/:realmPubKey/'
 *
 * TODO
 *  Currently we are not primarily listen to the uri for setting the realm due the current setup of setRealm and setRealms
 *  The route will be updated when we change the realm via setRealm().
 *  WHat we should do, is listen primarily to the route and set the realm (setRealm) accordingly
 */
export default function useLoadRealm(): {
  realm: Realm | undefined;
  realms: Realm[];
  loading: boolean;
  realmCount: number | undefined;
  reload: (signal?: AbortSignal, reload?: boolean) => void;
} {
  const { realm, realms, loading, realmCount, setRealm, loadRealms } = useRealm();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const load = (signal?: AbortSignal, reload?: boolean) => {
    if (user) {
      loadRealms()
        .then(realms => {
          if (signal?.aborted) return;
          if (realms.length === 0) return;

          // first check if we have a realm in the local storage
          // if we have a realm in the local storage, we set it as the current realm but only if it exists in the list of realms
          const currentSelectedRealmFromLocalStorage = localStorage.getItem(`selectedRealmPubKey.${user.id}`);
          const selectedRealmExists = realms.find(realm => realm.publicKey === currentSelectedRealmFromLocalStorage);
          if (selectedRealmExists) {
            setRealm(selectedRealmExists);
          } else {
            // check if we have a match in the url
            const realmPubKeyMatch = matchPath('/realm/:realmPubKey/*', location.pathname);
            const realmPubKey = realmPubKeyMatch ? realmPubKeyMatch.params.realmPubKey : undefined;

            // in case the realm and the route is not set, we navigate to the correct url
            // In case we are on the standalone page, we should not redirect
            if (!realm && !realmPubKey && !STANDALONE_PAGES.includes(location.pathname)) {
              // set the localstorage
              localStorage.setItem(`selectedRealmPubKey.${user.id}`, realms[0].publicKey);
              setRealm(realms[0]);
            }

            // in case the route has been set, but the realm not
            else if ((!realm || reload) && realmPubKey) {
              const currentRealm = realms.find(realm => realm.publicKey === realmPubKey);
              if (currentRealm) {
                localStorage.setItem(`selectedRealmPubKey.${user.id}`, currentRealm.publicKey);
                setRealm(currentRealm);
              }

              // or navigate to the first realm in the list if the current given realmPubKey route does not exists
              else {
                localStorage.setItem(`selectedRealmPubKey.${user.id}`, realms[0].publicKey);
                setRealm(realms[0]);
              }
            }
          }
        })
        .catch(() => {
          // TODO what if the user has no realms, he should not be able to navigate through the application
          // TODO show a blocking modal/popup with a warning?
        });
    }
  };

  /**
   * Check if we need to reroute from / --> /realms/publicKey
   */
  useEffect(() => {
    if (realms.length > 0 && location.pathname === '/') {
      // first check if we have a realm in the local storage
      // if we have a realm in the local storage, we set it as the current realm but only if it exists in the list of realms
      const currentSelectedRealmFromLocalStorage = localStorage.getItem(`selectedRealmPubKey.${user?.id}`);
      const selectedRealmExists = realms.find(realm => realm.publicKey === currentSelectedRealmFromLocalStorage);
      if (selectedRealmExists) {
        navigate(`/realm/${selectedRealmExists.publicKey}`);
      } else {
        navigate(`/realm/${realms[0].publicKey}`);
      }
    }
  }, [location, navigate, realms, user?.id]);

  /**
   * fetch the realms that belong to the user
   */
  useEffect(() => {
    const abortController = new AbortController();
    load(abortController.signal);
    return () => abortController.abort();
  }, [user]); /* eslint-disable-line */

  return {
    realm,
    realms,
    loading,
    realmCount,
    reload: load,
  };
}
