import React, { createContext, useContext, useState } from 'react';
import { logger } from '../util/Logger';
import { envVar } from '../util/Util/EnvVar';
import { RealmOrigin } from 'types/RealmOrigin';

const origin: string | undefined = envVar('ORIGIN', false);
export const DEFAULT_THEME = origin !== undefined && origin in RealmOrigin ? (origin as RealmOrigin) : RealmOrigin.Storro;

interface Props {
  theme: RealmOrigin;
  children: React.ReactNode;
}

type ThemeContextType = {
  theme: Props['theme'];
  setTheme: (theme: Props['theme']) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  theme: RealmOrigin.Storro,
  setTheme: () => logger.warn('no Theme provider'),
});

export function useThemeContext(): ThemeContextType {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }: Props): JSX.Element {
  const [theme, setTheme] = useState<Props['theme']>(DEFAULT_THEME);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}
