import React from 'react';
import { useNavigate } from 'react-router-dom';
import useRealm from './realm/UseRealm';
import { Realm } from 'types';
import { Confirm } from 'components/Modal/ConfirmModal';
import { toast } from 'react-toastify';
import { logger } from 'util/Logger';
import useUser from './useUser';

interface ReturnType {
  navigateToRealm: ({ realm, callback, confirm }: { realm: Realm; callback?: () => void; confirm: boolean }) => Promise<void>;
}

/**
 * Small helper to switch realms
 * When switching realms, we show a confirm modal
 */
function useRealmSwitcher(): ReturnType {
  const { loadRealm, realms } = useRealm();
  const navigate = useNavigate();
  const { user } = useUser();

  /**
   * First load the new realm and then navigate to it
   */
  const navigateAndLoadRealm = async (realmId: number, publicKey: string) => {
    try {
      await loadRealm(realmId);
      navigate(`/realm/${publicKey}`);
      localStorage.setItem(`selectedRealmPubKey.${user?.id}`, publicKey);
    } catch (e) {
      toast.error('Cannot load this realm. Please try again later.');
      logger.error(e);
    }
  };

  /**
   * Before we should navigate to the new realm, we show a confirm modal
   * but only if the user has more than 1 realm.
   */
  const navigateToRealm = async ({ realm, callback, confirm = true }: { realm: Realm; callback?: () => void; confirm: boolean }) => {
    if (realms.length > 1) {
      if (confirm) {
        Confirm({
          id: 'switch-realm',
          isSkippable: true,
          title: 'Your are switching realms',
          text: (
            <>
              Are you sure you want to switch to the realm <strong>{realm.name}</strong>
            </>
          ),
          confirmText: 'Switch realm',
          onConfirm: () => navigateAndLoadRealm(realm.id, realm.publicKey).then(callback),
        });
      } else {
        navigateAndLoadRealm(realm.id, realm.publicKey).then(callback);
      }
    } else {
      navigateAndLoadRealm(realm.id, realm.publicKey);
    }
  };

  return {
    navigateToRealm,
  };
}

export default useRealmSwitcher;
