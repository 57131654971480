import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { BinaryDownload } from '../../types';
import { detectOperationSystem } from '../../util/Util/DetectOperationSystem';
import Button, { ButtonVariant } from '../Button';
import Spinner from '../Spinner';
import useTheme from 'hooks/UseTheme';

interface Props {
  loading: boolean;
  binaries: BinaryDownload[];
  version: string;
  forRealm?: boolean; // we change the style when we show the list in the realm context
}

// get the current OS
const currentOperationSystem = detectOperationSystem();

/**
 * This hook will create a modal for creating a project
 */
export default function ListClientDownloads({ binaries, loading, version, forRealm = true }: Props): JSX.Element {
  const [primaryDownload, setPrimaryDownload] = useState<BinaryDownload>();
  const [otherDownloads, setOtherDownloads] = useState<BinaryDownload[]>([]);

  const { companyName } = useTheme();

  /**
   * set the primary and other downloads, based on the current OS
   */
  useEffect(() => {
    // set the primary download, the one that is for your current device OS
    setPrimaryDownload(binaries.find(binary => binary.operationSystem === currentOperationSystem));

    // filter the primary download out of the list.
    // in case of an unknown OS, this will keep the list to all items
    setOtherDownloads(binaries.filter(binary => binary.operationSystem !== currentOperationSystem));
  }, [binaries]);

  return (
    <div
      className={classNames({
        'flex flex-col h-full w-full items-center': !forRealm,
      })}
    >
      {loading && (
        <div className='flex items-center gap-x-1 justify-center'>
          <Spinner inverseColor={true} /> <span>Loading the binaries...</span>
        </div>
      )}

      {forRealm && (
        <p>
          We recommend that you download and install the {companyName} desktop client. The desktop client will create a {companyName} Drive
          on your local device for improved experience and speed. The application runs in the background, making sure that all your files
          are up-to-date and secured.
        </p>
      )}

      {!forRealm && <h1 className='text-xl font-medium mb-10 text-center'>Download and Install {companyName} desktop client.</h1>}

      <div
        className={classNames('flex flex-col gap-2 mt-3 mb-1', {
          grow: !forRealm,
        })}
      >
        {primaryDownload && (
          <div className='border-b w-full mb-2 pb-4'>
            <Button className='sm:w-full' variant={ButtonVariant.Primary} onClick={() => (location.href = primaryDownload.url)}>
              Download for {primaryDownload.operationSystem}
            </Button>
          </div>
        )}

        {otherDownloads.map(download => (
          <React.Fragment key={download.operationSystem}>
            <Button onClick={() => (location.href = download.url)}>Download for {download.operationSystem}</Button>
          </React.Fragment>
        ))}
      </div>

      <div className='mt-2 flex justify-end'>
        <span className='text-sm'>Client Version {version}</span>
      </div>
    </div>
  );
}
