import useTheme from './UseTheme';
import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * This hook return a Helmet component with the document head
 * Based on the them it use the correct favicon and title
 */
export default function useDocumentHead(pageTitle?: string): JSX.Element {
  const { companyName, isStorroTheme } = useTheme();

  const pageTitleWithSeperator = pageTitle ? ' - ' + pageTitle : '';
  const path = isStorroTheme ? '/assets/storro/' : '/assets/agency-drive/';

  return (
    <Helmet>
      <title>{companyName + pageTitleWithSeperator}</title>
      <meta name='theme-color' content='#000000' />
      <meta name='description' content={`${companyName} App`} />
      <meta name='author' content={companyName} />

      <link rel='icon' href={`${path}favicon.ico`} media='(prefers-color-scheme: light)' />
      <link rel='apple-touch-icon' sizes='180x180' href={`${path}apple-touch-icon.png`} media='(prefers-color-scheme: light)' />
      <link rel='icon' type='image/png' sizes='32x32' href={`${path}favicon-32x32.png`} media='(prefers-color-scheme: light)' />
      <link rel='icon' type='image/png' sizes='16x16' href={`${path}favicon-16x16.png`} media='(prefers-color-scheme: light)' />

      {/* Although Storro has no dark theme, the node element should exist; otherwise, Helmet will not render it inside a conditional tag. */}
      {/* So we have added the same dark icon for Storro that is also used for the light theme */}
      <link rel='icon' href={`${path}favicon-dark.ico`} media='(prefers-color-scheme: dark)' />
      <link rel='apple-touch-icon' sizes='180x180' href={`${path}apple-touch-icon-dark.png`} media='(prefers-color-scheme: dark)' />
      <link rel='icon' type='image/png' sizes='32x32' href={`${path}favicon-32x32-dark.png`} media='(prefers-color-scheme: dark)' />
      <link rel='icon' type='image/png' sizes='16x16' href={`${path}favicon-16x16-dark.png`} media='(prefers-color-scheme: dark)' />

      <link rel='manifest' href={`${path}site.webmanifest`} />
    </Helmet>
  );
}
