import useDocumentHead from 'hooks/UseDocumentHead';
import React from 'react';

interface Props {
  title?: string;
  children: JSX.Element;
}

/**
 * This hook wrapps the children with the document head
 * We used this in our routes to set the title of the page
 */
export default function RouteComponentWithHtmlHead({ children, title }: Props): JSX.Element {
  const head = useDocumentHead(title);

  return (
    <>
      {head}
      {children}
    </>
  );
}
