import debounce from 'debounce';
import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  TABLE,
  TABLE_HIDDEN_COLUMN_MD,
  TABLE_TBODY_TR,
  TABLE_THEAD_STICKY,
  TABLE_THEAD_TH_NAME,
  TABLE_THEAD_TR,
} from '../../helpers/CssClasses';
import useRealm from '../../hooks/realm/UseRealm';
import { Realm, SortOrder } from '../../types';
import { SubscriptionStatus } from '../../types/Payment';
import { friendlyStatusName } from '../../util/Util/FriendlyStatusName';
import Select from '../FormElements/Select';
import Tag, { TagSize, TagType } from '../Tag';
import classNames from 'classnames';
import useTableSort from 'hooks/UseTableSort';
import MobileFilterButton from 'components/FilterButton/MobileFilterButton';
import Spinner from 'components/Spinner';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import PageSelectionInput from 'components/Page/PageSelections/PageSelectionInput';
import useRealmSwitcher from 'hooks/UseRealmSwitcher';

interface Props {
  onSwitchRealm?: () => void;
}

enum StatusType {
  Any = 'any',
  InTrial = 'in trial',
  Active = 'active',
  NeedPayment = 'need payment',
  Cancelled = 'cancelled',
  NonRenewing = 'non_renewing',
}

export default function RealmTableUser({ onSwitchRealm }: Props): JSX.Element {
  const [filterStatus, setFilterStatus] = useState<StatusType>(StatusType.Any);
  const [filterName, setFilterName] = useState<string>();

  const { realms, loading } = useRealm();
  const isMobileDevice = useIsMobileDevice();
  const { navigateToRealm } = useRealmSwitcher();

  const {
    items: filteredRealms,
    requestSort,
    getSortElement,
    sortConfig,
  } = useTableSort<Realm>({
    config: { fieldName: 'name', direction: SortOrder.Ascending },
    items: realms,
  });

  /**
   * Apply the search filters
   */
  const filteredAndSortedRealms = useMemo(() => {
    let filteredArray: Realm[] = filteredRealms;

    // filter based on the role type
    if (filterStatus === StatusType.InTrial) {
      filteredArray = filteredArray.filter(realm => realm.status === SubscriptionStatus.Trial);
    } else if (filterStatus === StatusType.Active) {
      filteredArray = filteredArray.filter(realm => realm.status === SubscriptionStatus.Active);
    } else if (filterStatus === StatusType.Cancelled) {
      filteredArray = filteredArray.filter(realm => realm.status === SubscriptionStatus.Cancelled);
    } else if (filterStatus === StatusType.NeedPayment) {
      filteredArray = filteredArray.filter(realm => realm.needPayment);
    }

    // filter on name
    if (filterName) {
      filteredArray = filteredArray.filter(realm => realm.name.toLowerCase().includes(filterName.toLowerCase()));
    }

    return filteredArray;
  }, [filteredRealms, filterStatus, filterName]);

  /**
   * Show the Status inside a <tag /> and optional place the Need Payment badge
   */
  const StatusTag = ({ status, needPayment }: { status: SubscriptionStatus; needPayment?: boolean }) => {
    const needPaymentBadge =
      needPayment && status !== SubscriptionStatus.TrialExpired && status !== SubscriptionStatus.Cancelled ? (
        <Tag size={isMobileDevice ? TagSize.Small : TagSize.Medium} type={TagType.Danger}>
          Need payment
        </Tag>
      ) : (
        <></>
      );

    let tagType: TagType = TagType.Default;
    switch (status) {
      case SubscriptionStatus.Active:
        tagType = TagType.Success;
        break;
      case SubscriptionStatus.TrialExpired:
        tagType = TagType.Warning;
        break;
    }

    return (
      <>
        <Tag size={isMobileDevice ? TagSize.Small : TagSize.Medium} type={tagType}>
          {friendlyStatusName(status)}
        </Tag>{' '}
        {needPaymentBadge}
      </>
    );
  };

  const switchRealm = (givenRealm: Realm) => {
    navigateToRealm({ realm: givenRealm, confirm: false });
    onSwitchRealm?.();
  };

  /**
   * Put the onChange event in a debounce function to avoid a sluggy UI
   */
  const debouncedFilterName = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        setFilterName(event.target.value as string);
      }, 300),
    [],
  );

  const searchInputElement = useMemo(() => {
    return <PageSelectionInput key='search' label='Name' onChange={debouncedFilterName} className='w-full md:w-auto' />;
  }, [debouncedFilterName]);

  /**
   * Render the filter for the mobile view
   */
  const mobileFilter = useMemo(() => {
    return (
      <MobileFilterButton<Realm>
        asIcon={true}
        key='sort'
        fields={[
          ['name', 'Name'],
          ['status', 'Status'],
        ]}
        sortOrder={sortConfig.direction}
        sortBy={sortConfig.fieldName}
        onSortOrder={newSortOrder => requestSort(sortConfig.fieldName, newSortOrder)}
        onSortBy={newSortBy => requestSort(newSortBy)}
      />
    );
  }, [sortConfig.direction, sortConfig.fieldName, requestSort]);

  return (
    <>
      <div className='hidden md:flex gap-x-4 justify-end mb-2'>
        {searchInputElement}
        <div className='flex items-center gap-x-1'>
          <span>Status:</span>
          <Select options={Object.values(StatusType)} className='w-auto' onChange={e => setFilterStatus(e.target.value as StatusType)} />
        </div>
      </div>

      <table className={TABLE}>
        <thead className={TABLE_THEAD_STICKY}>
          <tr className={classNames(TABLE_THEAD_TR)}>
            <th className={classNames(TABLE_HIDDEN_COLUMN_MD, TABLE_THEAD_TH_NAME)} onClick={() => requestSort('name')}>
              Name {getSortElement('name')}
            </th>

            <th className={classNames(TABLE_HIDDEN_COLUMN_MD)} onClick={() => requestSort('status')}>
              Status {getSortElement('status')}
            </th>

            <th className='md:hidden font-normal'>
              <div className='flex justify-between gap-x-2 items-center'>
                {searchInputElement}
                {mobileFilter}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td className='w-full'>
                <div className='flex items-center gap-x-2 text-sm'>
                  <Spinner inverseColor={true} /> Loading...
                </div>
              </td>
            </tr>
          )}

          {!loading && filteredAndSortedRealms.length === 0 && (
            <tr>
              <td className='w-full'>
                <div className='py-2 italic'>No realms meet the search criteria</div>
              </td>
            </tr>
          )}

          {filteredAndSortedRealms.map(realm => (
            <tr key={realm.id} className={TABLE_TBODY_TR} onClick={() => switchRealm(realm)}>
              <td className={TABLE_HIDDEN_COLUMN_MD}>{realm.name}</td>

              <td className={TABLE_HIDDEN_COLUMN_MD}>
                {realm.status && (
                  <div className='flex gap-2'>
                    <StatusTag status={realm.status} needPayment={realm.needPayment} />
                  </div>
                )}
              </td>

              <td className='md:hidden'>
                <div className='my-2 space-y-1'>
                  <p>{realm.name}</p>
                  {realm.status && (
                    <div className='flex gap-2'>
                      <StatusTag status={realm.status} needPayment={realm.needPayment} />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
